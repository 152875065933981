export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_RESET = 'LOGIN_RESET';
export const LOGOUT = 'LOGOUT';
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const SUBSCRIPTION_REQUEST = 'SUBSCRIPTION_REQUEST';
export const SUBSCRIPTION_SUCCESS = 'SUBSCRIPTION_SUCCESS';
export const SUBSCRIPTION_FAILURE = 'SUBSCRIPTION_FAILURE';
export const SUBSCRIPTION_RESET = 'SUBSCRIPTION_RESET';
export const REGISTER_RESET = 'REGISTER_RESET';
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const FETCH_HOME_REQUEST = 'FETCH_HOME_REQUEST';
export const FETCH_HOME_SUCCESS = 'FETCH_HOME_SUCCESS';
export const FETCH_HOME_FAILURE = 'FETCH_HOME_FAILURE';
export const FETCH_USER_PROFILE_REQUEST = 'FETCH_USER_PROFILE_REQUEST';
export const FETCH_USER_PROFILE_SUCCESS = 'FETCH_USER_PROFILE_SUCCESS';
export const FETCH_USER_PROFILE_FAILURE = 'FETCH_USER_PROFILE_FAILURE';
export const FETCH_THREAD_REQUEST = 'FETCH_THREAD_REQUEST';
export const FETCH_THREAD_SUCCESS = 'FETCH_THREAD_SUCCESS';
export const FETCH_THREAD_FAILURE = 'FETCH_THREAD_FAILURE';
export const CREATE_THREAD_REQUEST = 'CREATE_THREAD_REQUEST';
export const CREATE_THREAD_SUCCESS = 'CREATE_THREAD_SUCCESS';
export const RESET_THREAD_SUCCESS = 'RESET_THREAD_SUCCESS';
export const CREATE_THREAD_FAILURE = 'CREATE_THREAD_FAILURE';
export const UPDATE_THREAD_REQUEST = 'UPDATE_THREAD_REQUEST';
export const UPDATE_THREAD_SUCCESS = 'UPDATE_THREAD_SUCCESS';
export const UPDATE_THREAD_FAILURE = 'UPDATE_THREAD_FAILURE';
export const CREATE_THREAD_SAVE = 'CREATE_THREAD_SAVE';
export const UPDATE_THREAD_TAGS = 'UPDATE_THREAD_TAGS';
export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';
export const UPDATE_PROFILE_RESET = 'UPDATE_PROFILE_RESET';
export const ADD_FILTER = 'ADD_FILTER';
export const ALTER_SORT = 'ALTER_SORT';
export const SWITCH_PAGE = 'SWITCH_PAGE';
export const UPDATE_CUR_POST = 'UPDATE_CUR_POST';
export const SET_KEY = 'SET_KEY';
export const CREATE_POST_REQUEST = 'CREATE_POST_REQUEST';
export const CREATE_POST_SUCCESS = 'CREATE_POST_SUCCESS';
export const CREATE_POST_FAILURE = 'CREATE_POST_FAILURE';
export const DELETE_POST_REQUEST = 'DELETE_POST_REQUEST';
export const DELETE_POST_SUCCESS = 'DELETE_POST_SUCCESS';
export const DELETE_POST_FAILURE = 'DELETE_POST_FAILURE';
export const EDIT_POST_REQUEST = 'EDIT_POST_REQUEST';
export const EDIT_POST_SUCCESS = 'EDIT_POST_SUCCESS';
export const EDIT_POST_FAILURE = 'EDIT_POST_FAILURE';
export const OPEN_POST_EDITOR = 'OPEN_POST_EDITOR';
export const REFRESH_FIREBASE_TOKEN = 'REFRESH_FIREBASE_TOKEN';
export const UPDATE_VOTE_THREAD = 'UPDATE_VOTE_THREAD';